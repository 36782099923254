import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Label } from './forms';

const InputField = ({ name, label, required, type, placeholder }) => (
  <Field name={name} type={type || 'text'}>
    {({ input, meta }) => (
      <div className={`form-group ${meta.submitError ? 'has-error' : ''}`}>
        <Label name={name} title={`${label}${required ? ' *' : ''}`} />
        <div className="col-sm-5">
          <input className="form-control" placeholder={placeholder} id={name} {...input} />
        </div>
        <div className="col-sm-4">
          {meta.submitError &&
            <span className="help-block">{meta.submitError}</span>
          }
        </div>
      </div>
    )}
  </Field>
);

InputField.propTypes = {
  // The name of the form field handled by this input control
  name:        PropTypes.string.isRequired,

  // A translation of the label for this field
  label:       PropTypes.string.isRequired,

  // Flag causing the field to be marked as required
  required:    PropTypes.bool,

  // The type of HTML control to be displayed. Defaults to 'text'
  type:        PropTypes.string,

  // A translated string to be used as the placeholder text
  placeholder: PropTypes.string
};

export default InputField;
