import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Admin = () => (
  <>
    <div className="page-header">
      <div className="label label-environment pull-right label-admin">
        <FormattedMessage id="admin.title" />
      </div>
      <h1>
        <FormattedMessage id="admin.title" />
      </h1>
    </div>
    <Outlet />
  </>
);

export default Admin;
