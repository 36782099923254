import useAxios from 'axios-hooks';

import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeCache, loadCache } from 'axios-hooks';
import useEnv from './use-env';
import useI18n from './use-i18n';
import { stringToSelectValue } from './react-select-items';

export const baseURL = process.env.REACT_APP_API_BASE_URL;
export const PAGE_SIZE = 50;

export const useResource = (url, params, useCache) => {
  if (useCache === undefined)
    useCache = true;

  return useAxios({ url, params, baseURL }, { useCache })
};

export const useChannels = () => useResource('/channels', { "page[size]": 1000 });

const compactObject = (object) => {
  Object.keys(object).forEach(key => {
    if (!object[key]) delete object[key];
  })

  return object;
};

const orderParams = (env, searchParams) => compactObject({
  env,
  "filters[company]": stringToSelectValue(searchParams.get('company')),
  "filters[query]": searchParams.get('query'),
  "filters[period][from]": searchParams.get('from'),
  "filters[period][to]": searchParams.get('to')
});

export const orderQueryString = (env, searchParams) => (
  new URLSearchParams(orderParams(env, searchParams)).toString()
);

export const useOrders = () => {
  const [searchParams] = useSearchParams();
  const [env] = useEnv();

  return useResource('/orders', {
    ...orderParams(env, searchParams),
    "page[number]": searchParams.get('page') || 1,
    "page[size]": PAGE_SIZE
  });
}

export const useAll = (hooks) => {
  const datas = {};
  let anyLoading = false;
  let anyErrors = false;

  for (const name in hooks) {
    const [{ data, loading, error }] = hooks[name];
    if (data)
      datas[name] = data[name];
    anyLoading ||= loading;
    anyErrors ||= error;
  }

  return { ...datas, loading: anyLoading, error: anyErrors };
}

export const useFinalFormHandler = (handler) => {
  const { translate } = useI18n();
  const navigate = useNavigate();

  return async (params) => {
    try {
      await handler(params);
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        toast.error(translate('errors.timeout'));
        return;
      }

      const { response } = error;
      if (response) {
        if (response.status === 401 || response.status === 403)
          navigate('/sessions/new');

        if (response.status >= 500)
          toast.error(translate('errors.server'));

        if (response.status === 404)
          toast.error(translate('errors.not_found'));

        if (response.data)
          return response.data.errors;
      } else {
        toast.error(translate('errors.network'));
      }
    }
  };
}

export const updateCachedRecord = async (pluralType, singularType, resp) => {
  const cache = await serializeCache(),
        record = resp.data[singularType];

  cache.forEach((item) => {
    const req = JSON.parse(item.k),
          cachedResp = item.v;

    // update the list, there might be several since the list is paginated
    if (req.url === `/${pluralType}`) {
      const list = cachedResp.data[pluralType];

      list.forEach((item, index) => {
        if (item.id === record.id) list[index] = record;
      });
    }

    // update the response of fetching a single record
    if (req.url === `/${pluralType}/${record.id}`) {
      cachedResp.data[singularType] = record;
    }
  });

  return loadCache(cache);
};

// use to clear cache for lists, for example, companies
export const deleteCacheFor = async (pluralType) => {
  const cache = await serializeCache(),
        indexes = [];

  cache.forEach((item, index) => {
    const req = JSON.parse(item.k);

    // there might be several since the list is paginated
    if (req.url === `/${pluralType}`) indexes.push(index);
  });

  indexes.forEach((index) => cache.splice(index, 1));

  return loadCache(cache);
};
