import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import CompanyForm from 'components/company-form';
import Loader from 'components/loader';

import useI18n from 'lib/use-i18n';
import {
  useCompanySizes, useIndustries, useTokens,
  enumToOption, tokenToOption
} from 'lib/api/enums';
import { useAll, useFinalFormHandler } from 'lib/api';
import { useCompany, updateCompany } from 'lib/api/companies';

const EditCompany = () => {
  const { companyId } = useParams();
  const companyHook = useCompany();
  const { company, company_sizes: companySizes, industries, tokens, loading, error } = useAll({
    company: companyHook, company_sizes: useCompanySizes(), industries: useIndustries(), tokens: useTokens()
  });
  const navigate = useNavigate();

  const { translate } = useI18n();

  const handleCompanyUpdate = useFinalFormHandler(async (formData) => {
    await updateCompany(companyId, formData);
    navigate(`/companies/${companyId}`);
  });

  if (loading)
    return <Loader />;

  if (error)
    return <div className="text-center">
      <h4>
        <span className="glyphicon glyphicon-fire"></span>
        &nbsp;
        <FormattedMessage id="errors.server" />
      </h4>
    </div>;

  return <CompanyForm initialValues={{
                        name: company.name,
                        industry: enumToOption(industries, company.industry),
                        city: company.city,
                        company_size: enumToOption(companySizes, company.company_size),
                        uri: company.uri,
                        career_site_url: company.career_site_url,
                        xing_profile_url: company.profile_urls.xing,
                        linkedin_profile_url: company.profile_urls.linkedin,
                        linkedin_reference: company.linkedin_reference,
                        token_id: tokenToOption(company.token)
                      }}
                      onSubmit={handleCompanyUpdate}
                      submitTitle={translate('companies.update')}
                      cancelTo={`/companies/${companyId}`}
                      industries={industries}
                      companySizes={companySizes}
                      tokens={tokens} />;
};

export default EditCompany;
