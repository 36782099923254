import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { signIn } from 'lib/api/sessions';
import useI18n from 'lib/use-i18n';

const SignIn = () => {
  const navigate = useNavigate();
  const { translate } = useI18n();

  const handleLogin = async (params) => {
    try {
      const response = await signIn(params);

      // devise-otp uses this header to signal that a 2FA code is required
      if (response.headers && response.headers.location) {
        const match = response.headers.location.match(/challenge=([0-9a-f]+)/);
        if (match && match[1])
          return navigate('/sessions/otp', { state: { challenge: match[1] } });
      }

      navigate('/');
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        toast.error(translate('errors.timeout'));
        return;
      }

      const { response } = error;
      if (response) {
        if (response.status === 401)
          return { password: translate('errors.password') };

        if (response.data)
          return response.data.errors;

        if (response.status >= 500)
          toast.error(translate('errors.server'));
      } else {
        toast(translate('errors.network'));
      }
    }
  };

  return <>
    <h2><FormattedMessage id="sessions.new.title" /></h2>
    <h3><FormattedMessage id="sessions.new.header" /></h3>

    <Form onSubmit={handleLogin}
          render={({ handleSubmit, submitting }) => (
            <form className="form-vertical" onSubmit={handleSubmit}>
              <Field name="email">
                {({ input, meta }) => (
                  <div className="form-group">
                    {meta.submitError &&
                      <span className="help-block">{meta.submitError}</span>
                    }
                    <label htmlFor="email" className="control-label">
                      <FormattedMessage id="sessions.new.email" />
                    </label>
                    <input id="email" className="form-control" {...input} autoFocus />
                  </div>
                )}
              </Field>

              <Field name="password" type="password">
                {({ input, meta }) => (
                  <div className="form-group">
                    <label htmlFor="password" className="control-label">
                      <FormattedMessage id="sessions.password" />
                    </label>
                    <input id="password" className="form-control" {...input} />
                    {meta.submitError &&
                      <span className="help-block">{meta.submitError}</span>
                    }
                  </div>
                )}
              </Field>

              <div className="clearfix">
                <Link to="/sessions/forgot" className="login-link">
                  <FormattedMessage id="sessions.new.forgot_password" />
                </Link>
                <button type="submit" disabled={submitting}
                        className="btn btn-primary btn-large pull-right button">
                  <FormattedMessage id="sessions.new.submit" />
                </button>
              </div>
            </form>
          )} />
  </>;
}

export default SignIn;
