import { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import QRCode from "react-qr-code";
import { toast } from 'react-toastify';

import Loader from 'components/loader';
import AxiosError from 'components/axios-error';

import { useSession, disableOtp, useOtpSetup, confirmOtp } from 'lib/api/sessions';
import useI18n from 'lib/use-i18n';

const SetupOtp = ({ onConfirmed }) => {
  const [{ data, loading, error }] = useOtpSetup();
  const otpToken = useRef(null);
  const { translate } = useI18n();

  if (loading) return <Loader />;

  if (error) return <AxiosError error={error} type="settings" />;

  const { otp } = data;

  const handleConfirmOtp = async () => {
    const { value } = otpToken.current;
    if (!value) {
      toast.error(translate('errors.blank_code'));
      return;
    }

    try {
      await confirmOtp(value);
      onConfirmed();
    } catch (error) {
      if (error.response && error.response.status === 422)
        toast.error(translate('errors.otp_token'));
    }
  }

  return <>
    <p><FormattedMessage id="settings.otp.setup.description" /></p>
    <QRCode value={otp.otp_provisioning_uri} />;
    <p><FormattedMessage id="settings.otp.setup.manual" /></p>
    <p><code>{otp.otp_auth_secret}</code></p>
    <p><label htmlFor="token"><FormattedMessage id="settings.otp.setup.confirm" /></label></p>
    <p><input id="token" ref={otpToken} maxLength={6} /></p>
    <p>
      <button className="btn btn-primary" onClick={handleConfirmOtp}>
        <FormattedMessage id="settings.otp.setup.submit_confirmation" />
      </button>
    </p>
  </>;
}

const Settings = () => {
  const [{ data, loading, error }, reloadSession] = useSession();
  const [otpUiState, setOtpUiState] = useState('show');

  if (loading) return <Loader />;

  if (error) return <AxiosError error={error} type="settings" />;

  const { session } = data;
  const otpEnabled = session.otp_enabled;

  const setOtpHandler = async () => {
    setOtpUiState('setup');
  };
  const unsetOtpHandler = async () => {
    await disableOtp();
    reloadSession();
  };

  const confirmOtpHandler = () => {
    reloadSession();
    setOtpUiState('show');
  };

  return <>
    <div className="panel-heading">
      <h2>
        <FormattedMessage id="settings.otp.title" />
        &nbsp;
        <span role="note" className="badge">
          {otpEnabled ?
            <FormattedMessage id="settings.otp.enabled" /> :
            <FormattedMessage id="settings.otp.disabled" />}
        </span>
      </h2>
    </div>

    <div className="panel-body">
      {otpUiState === 'show' && (
        otpEnabled ?
          <button className="btn btn-primary" onClick={unsetOtpHandler}>
            <FormattedMessage id="settings.otp.disable" />
          </button> :
          <button className="btn btn-primary" onClick={setOtpHandler}>
            <FormattedMessage id="settings.otp.enable" />
          </button>)}

      {otpUiState === 'setup' && <SetupOtp onConfirmed={confirmOtpHandler} />}
    </div>
  </>
}

export default Settings
