import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import PaginatedList from 'components/paginated-list';

import { useAdminUsers, deleteAdminUser } from 'lib/api/admin';
import useI18n from 'lib/use-i18n';

const handleDelete = (record, reload, message) => async () => {
  if (!window.confirm(message))
    return;

  await deleteAdminUser(record.id);

  reload();
}

const AdminUser = ({ record, reload }) => {
  const { translate } = useI18n('admin.users');

  const onDelete = handleDelete(
    record, reload, translate('.confirm_delete', { email: record.email })
  );

  return <tr>
    <td className="text-break">{record.email}</td>
    <td className="text-break text-right">
      <ul className="list-inline">
        {record.admin ?
          <>
            <li>
              <span className="glyphicon glyphicon-king"
                    title={translate('admin.title')} />
            </li>
            <li>
              <span className="glyphicon glyphicon-trash"
                    role="note"
                    title={translate('.cannot_delete')} />
            </li>
          </> :
          <li>
            <button className="btn btn-link" title={translate('buttons.delete')}
               onClick={onDelete}>
              <span className="glyphicon glyphicon-trash" />
            </button>
          </li>}
      </ul>
    </td>
  </tr>;
}

const ListAdminUsers = () => (
  <table className="table table-striped table-hover">
    <thead>
      <tr>
        <th className="col-md-9">
          <FormattedMessage id="admin.users.email" />
        </th>
        <th className="col-md-3">
          <Link to="/admin/users/new">
            <span className="glyphicon glyphicon-plus" />
            &nbsp;
            <FormattedMessage id="admin.users.new" />
          </Link>
        </th>
      </tr>
    </thead>

    <PaginatedList type="partner_users" axiosHook={useAdminUsers()} Row={AdminUser} />
  </table>
);

export default ListAdminUsers;
