import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useCompany } from 'lib/api/companies';
import useI18n from 'lib/use-i18n';

import { ShowField } from 'components/forms';

const ShowCompany = () => {
  const [{ data }] = useCompany();
  const { translate } = useI18n('companies.fields');

  // layouts/company already takes care of loading the
  // company and displaying loader/errors.
  if (!data) return;

  const { company } = data;

  return <>
    <div className="form-horizontal" role="main">
      <ShowField title={translate('.name')} value={company.name} />
      <ShowField title={translate('.industry')} value={company.industry} />
      <ShowField title={translate('.city')} value={company.city} />
      <ShowField title={translate('.company_size')} value={company.company_size} />
      <ShowField title={translate('.uri')} value={company.uri} />
      <ShowField title={translate('.career_site_url')} value={company.career_site_url} />
      <ShowField title={translate('.xing_profile_url')} value={company.profile_urls.xing} />
      <ShowField title={translate('.linkedin_profile_url')} value={company.profile_urls.linkedin} />
      <ShowField title={translate('.linkedin_reference')} value={company.linkedin_reference} />
      <ShowField title={translate('.token_id')} value={company.token.note} />

      <div className="form-group">
        <div className="col-sm-3"></div>
        <Link to={`/companies/${company.id}/edit`}>
          <span className="glyphicon glyphicon-edit"></span>
          &nbsp;
          <FormattedMessage id="companies.edit" />
        </Link>
      </div>
    </div>
  </>;
};

export default ShowCompany;
