import axios from 'axios';

import { baseURL, useResource } from '../api';

export const useSession = () => useResource('/sessions/current');

export const signIn = (params) => (
  axios.post(`${baseURL}/auth/sign_in`, {}, {
    auth: { username: params.email, password: params.password }
  })
);

export const signInOtp = (params) => (
  axios.patch(`${baseURL}/auth/otp/credentials`, { partner_user: params })
);

export const signOut = () => axios.delete(`${baseURL}/auth/sign_out`);

export const disableOtp = () => axios.delete(`${baseURL}/sessions/otp`);

export const useOtpSetup = () => useResource('/sessions/otp', null, false);

export const confirmOtp = (token) => axios.put(`${baseURL}/sessions/otp`, { token });

export const confirmUser = (params) => axios.put(`${baseURL}/sessions/confirm`, params);

export const resetPassword = (params) => axios.put(`${baseURL}/sessions/reset`, params);

export const forgotPassword = (params) => (
  axios.post(`${baseURL}/auth/password`, { partner_user: params })
);
