import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useChannels, useFinalFormHandler } from 'lib/api';
import { createFrameContract } from 'lib/api/frame-contracts';

import FrameContractForm from 'components/frame-contract-form';
import Loader from 'components/loader';

const CreateFrameContract = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const [{ data, loading, error }] = useChannels();

  const handleFrameContractCreate = useFinalFormHandler(async (params) => {
    const response = await createFrameContract(companyId, params);
    navigate(`/companies/${companyId}/frame_contracts/${response.data.frame_contract.id}`);
  });

  if (loading)
    return <Loader />;

  if (error)
    return <div className="text-center">
      <h4>
        <span className="glyphicon glyphicon-fire"></span>
        &nbsp;
        <FormattedMessage id="errors.server" />
      </h4>
    </div>;

  const { channels } = data;

  return <FrameContractForm onSubmit={handleFrameContractCreate}
                            channels={channels}
                            cancelTo={`/companies/${companyId}/frame_contracts`} />;
}

export default CreateFrameContract;
