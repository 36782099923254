import { useSearchParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useFrameContracts, deleteFrameContract } from 'lib/api/frame-contracts';
import useI18n from 'lib/use-i18n';

import PaginatedList from 'components/paginated-list';

const STATES = ['draft', 'requested', 'pending', 'active', 'expired', 'rejected'];
const FLATRATE_PRICING_TYPE = 'flat';

const StateButton = ({ state, selected, setState }) => {
  const { translate } = useI18n();
  const label = translate(`frame_contracts.states.${state}`);

  return selected ?
    <li className="page active">
      <span role="note" aria-label={label}>
        {label}
      </span>
    </li> :
    <li className="page">
      <a href="#" onClick={setState}>
        {label}
      </a>
    </li>;
};

const StateFilter = ({ selected, setState }) => (
  <ul className="pagination" role="search">
    <StateButton state="all" selected={!selected} setState={() => setState(null)} />
    {STATES.map(
      state => <StateButton key={state}
                            state={state}
                            selected={selected === state}
                            setState={() => setState(state)} />
    )}
  </ul>
);

const handleDelete = (record, reload, message) => async () => {
  if (!window.confirm(message))
    return;

  await deleteFrameContract(record.id);

  reload();
}

const FrameContract = ({ record, reload }) => {
  const { translate } = useI18n('frame_contracts');

  return <tr>
    <td className="text-break">
      <FormattedMessage id={`frame_contracts.states.${record.state}`} />
    </td>
    <td className="text-break">
      <strong>{record.channel.name}</strong>
      {record.description && <div className="small">{record.description}</div>}
    </td>
    <td className="text-break text-right">
      {record.pricing_type === FLATRATE_PRICING_TYPE ?
        <>&infin;</> :
        <FormattedMessage id="x_of_y" values={{ x: record.total_amount, y: record.initial_amount }} />
      }
    </td>
    <td className="text-break text-right">
      {record.expires_at || <>&mdash;</>}
    </td>
    <td className="text-break text-right">
      <ul className="list-inline">
        <li>
          {record.state === 'draft' ?
            <Link to={`${record.id}/edit`} title={translate('frame_contracts.edit')}>
              <span className="glyphicon glyphicon-edit"></span>
            </Link> :
            <Link to={`${record.id}`}>
              <span aria-label={translate('frame_contracts.show')}
                    className="glyphicon glyphicon-eye-open"></span>
            </Link>}
        </li>
        <li>
          {record.used ?
            <span className="glyphicon glyphicon-trash"
                  role="note"
                  title={translate('.cannot_be_removed')}></span> :
            <a title={translate('buttons.delete')} role="button"
              onClick={
                handleDelete(record, reload, translate('.confirm_delete', { channel_name: record.channel.name }))
            }>
              <span className="glyphicon glyphicon-trash"></span>
            </a>
          }
        </li>
      </ul>
    </td>
  </tr>;
}

const ListFrameContracts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const state = searchParams.get('state');
  const setState = (state) => setSearchParams(state ? { state } : {});

  return <>
    <StateFilter selected={state} setState={setState} />
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th className="col-md-1">
            <FormattedMessage id="frame_contracts.fields.state" />
          </th>
          <th className="col-md-2">
            <FormattedMessage id="frame_contracts.fields.channel_id" />
          </th>
          <th className="col-md-3 text-right">
            <FormattedMessage id="frame_contracts.fields.current_amount" />
          </th>
          <th className="col-md-2 text-right">
            <FormattedMessage id="frame_contracts.fields.expires_at" />
          </th>
          <th className="col-md-1 text-right"></th>
        </tr>
      </thead>

      <PaginatedList type="frame_contracts" axiosHook={useFrameContracts()} Row={FrameContract} />
    </table>
  </>;
};

export default ListFrameContracts;
