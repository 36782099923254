import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { PAGE_SIZE } from 'lib/api';

import Loader from './loader';
import Pager from './pager';
import AxiosError from './axios-error';

const LoaderRow = () => (
  <tbody><tr><td colSpan="100%">
    <Loader />
  </td></tr></tbody>
);

const Error = ({ error }) => (
  <tbody>
    <tr className="danger">
      <td colSpan="100%" className="text-center">
        <AxiosError error={error} />
      </td>
    </tr>
  </tbody>
);

const Empty = ({ type }) => (
  <tbody>
    <tr className="info">
      <td colSpan="100%" className="text-center text-muted">
        <FormattedMessage id={`${type}.no_records`}
                          default="No records found." />
      </td>
    </tr>
  </tbody>
);

const PagerFoot = ({ meta }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page')) || 1;

  if (meta['record-count'] <= PAGE_SIZE)
    return <tfoot><tr><td colSpan="100%"></td></tr></tfoot>;

  const setPage = (newPage) => {
    if (newPage === 1)
      searchParams.delete('page');
    else
      searchParams.set('page',  newPage);
    setSearchParams(searchParams);
  };

  return <tfoot>
    <tr>
      <td className="text-center" colSpan="100%">
        <Pager page={page} size={PAGE_SIZE} count={meta['record-count']}
               onPage={setPage} />
      </td>
    </tr>
  </tfoot>;
};

const PaginatedList = ({ type, axiosHook, Row }) => {
  const [{ data, loading, error }, reload] = axiosHook;

  if (loading) return <LoaderRow />;

  if (error) return <Error error={error} />;

  if (data[type].length === 0) return <Empty type={type} />;

  const payload = data[type];
  return <>
    <tbody>{payload.map(record => <Row key={record.id} record={record} reload={reload} />)}</tbody>
    <PagerFoot meta={data.meta} />
  </>;
};

PaginatedList.propTypes = {
  // A name for the type of data being shown, e.g. 'companies'.
  // Used for i18n and for retrieving data from the payload.
  type:      PropTypes.string.isRequired,

  // The return value from a call to useAxios, expected to
  // contain the data to be displayed.
  axiosHook: PropTypes.array.isRequired,

  // A react component type that will be used to render
  // elements in the list. Rows will be passed two parameters:
  // - record: The record to be rendered
  // - reload: A function that can be called to reload the list
  Row:       PropTypes.elementType.isRequired
};

export default PaginatedList;
