import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';

import useEnv from 'lib/use-env';

import { useResource, PAGE_SIZE, baseURL, updateCachedRecord, deleteCacheFor } from '../api';

export const useCompanies = () => {
  const [searchParams] = useSearchParams();
  const [env] = useEnv();

  return useResource('/companies', {
    env,
    name: searchParams.get('name'),
    "page[number]": searchParams.get('page') || 1,
    "page[size]": PAGE_SIZE
  });
}

export const searchCompanies = (env, name) => (
  axios.get(`${baseURL}/companies`, { params: {
    env, name, search: true, "page[number]": 1, "page[size]": 10
  }})
);

export const useCompany = () => {
  const { companyId } = useParams();
  return useResource(`/companies/${companyId}`);
}

export const updateCompany = async (companyId, formData) => {
  const resp = await axios.put(`${baseURL}/companies/${companyId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  await updateCachedRecord('companies', 'company', resp);

  return resp;
};

export const createCompany = async (formData) => {
  const resp = await axios.post(`${baseURL}/companies`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  // cache for companies must be cleared, if the list is paginated, we don't know
  // where a new record should appear
  deleteCacheFor('companies');

  return resp;
};
