import { useNavigate, Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import InputField from 'components/input-field';

import { useFinalFormHandler } from 'lib/api';
import { createAdminUser } from 'lib/api/admin';
import useI18n from 'lib/use-i18n';

const CreateAdminUser = () => {
  const navigate = useNavigate();
  const { translate } = useI18n('admin.users');

  const handleUserCreate = useFinalFormHandler(async (params) => {
    await createAdminUser(params);
    navigate('/admin/users');
  });

  return <div className="container-fluid padded">
    <h2><FormattedMessage id="admin.users.new" /></h2>
    <Form onSubmit={handleUserCreate}
          render={({ handleSubmit, submitting }) => (
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <InputField name="email" label={translate('.email')} />

              <div className="form-group">
                <div className="col-sm-offset-3 col-sm-9">
                  <button className="btn btn-primary" type="submit" disabled={submitting}>
                    <FormattedMessage id="buttons.create" />
                  </button>
                  <Link to="/admin/users" className="btn btn-danger">
                    <FormattedMessage id="buttons.cancel" />
                  </Link>
                </div>
              </div>
            </form>
          )} />
  </div>;
};

export default CreateAdminUser;
