/**
 * Convert an object in the structure used by react-select into a string.
 * This implementation is very specific to our usage; values are UUID, and
 * the ~ character was chosen as a separator because it does not appear in
 * the names of any companies in our system.
 */
export const selectItemToString = ({ value, label }) => `${value}~${label}`;

const TO_ITEM = /^([^~]+)(~(.*))?/

/**
 * Convert strings returned by the above function back into a struct
 * used by react-select.
 */
export const stringToSelectItem = (str) => {
  if (!str) return null;

  const [, value, , label] = str.match(TO_ITEM);
  return { value, label };
}

export const stringToSelectValue = (str) => str && stringToSelectItem(str).value;
