import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import useI18n from 'lib/use-i18n';

import './sessions.css';

const Sessions = () => {
  const { translate } = useI18n();

  useEffect(() => {
    document.body.className = 'login-layout';

    return () => document.body.className = '';
  }, []);

  return <>
    <header>
      <div className="logo">
        <img src="/squared-logo.png"
             alt={translate('gohiring.name')}
             className="img-circle"
             width="100" height="100" />
      </div>
    </header>
    <div className="container">
      <div className="well login-container">
        <Outlet />
      </div>
    </div>
    <footer>
      <FormattedMessage id="gohiring.copy" />
    </footer>
  </>;
}

export default Sessions;
