import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useFrameContract } from 'lib/api/frame-contracts';
import useI18n from 'lib/use-i18n';

import { ShowField } from 'components/forms';

const ShowFrameContract = () => {
  const [{ data }] = useFrameContract();
  const { translate } = useI18n('frame_contracts.fields');

  if (!data) return;

  const { frame_contract: frameContract } = data;

  return <>
    <div className="form-horizontal" role="main">
      <ShowField title={translate('.channel_id')} value={frameContract.channel.name} />
      <ShowField title={translate('.state')}
                 value={translate(`frame_contracts.states.${frameContract.state}`)} />
      <ShowField title={translate('.code')} value={frameContract.code} />
      <ShowField title={translate('.description')} value={frameContract.description} />
      <ShowField title={translate('.posting_email')} value={frameContract.posting_email} />
      <ShowField title={translate('.pricing_type')}
                 value={translate(frameContract.pricing_type ?
                   'frame_contracts.pricing_types.flatrate' :
                   'frame_contracts.pricing_types.contingent')} />
      <ShowField title={translate('.posting_price')} value={frameContract.posting_price} />
      <ShowField title={translate('.activates_at')} value={frameContract.activates_at} />
      <ShowField title={translate('.expires_at')} value={frameContract.expires_at} />

      {frameContract.state === 'draft' &&
        <div className="form-group">
          <div className="col-sm-3"></div>
          <Link to="edit">
            <span className="glyphicon glyphicon-edit"></span>
            &nbsp;
            <FormattedMessage id="frame_contracts.edit" />
          </Link>
        </div>
      }
    </div>
  </>;
}

export default ShowFrameContract;
