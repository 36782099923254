import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';

import { Label } from './forms';

const RadioField = ({ name, label, required, options }) => {
  const { meta } = useField(name);

  return <div className={`form-group ${meta.submitError ? 'has-error' : ''}`}>
    <Label title={`${label}${required ? ' *' : ''}`} />
    <div className="col-sm-5">
      {options.map(({ value, label }) => {
        const id = `${name}_${value}`;

        return <Field key={value} name={name} value={value} type="radio">
          {({ input }) => (
            <label htmlFor={id} className="radio-inline">
              <input id={id} {...input} />
              {label}
            </label>
          )}
        </Field>
      })}
    </div>
    <div className="col-sm-4">
      {meta.submitError &&
        <span className="help-block">{meta.submitError}</span>
      }
    </div>
  </div>;
}

RadioField.propTypes = {
  // The name of the form field handled by this input control
  name:     PropTypes.string.isRequired,

  // A translation of the label for this field
  label:    PropTypes.string.isRequired,

  // Flag causing the field to be marked as required
  required: PropTypes.bool,

  // A list of options available to be selected
  options:  PropTypes.arrayOf(PropTypes.shape({
    // The translated label to be shown for the item
    label: PropTypes.string.isRequired,

    // The value to set the field to when this item is selected
    value: PropTypes.string.isRequired
  })),
};

export default RadioField;
