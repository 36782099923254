import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useI18n from 'lib/use-i18n';

import InputField from './input-field';

const UserForm = ({ companyId, onSubmit }) => {
  const { translate } = useI18n('users');

  return <div className="container-fluid padded">
    <Form onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <InputField name="salutation" label={translate('.fields.salutation')} />
              <InputField name="first_name" label={translate('.fields.first_name')} required />
              <InputField name="last_name" label={translate('.fields.last_name')} required />
              <InputField name="email" type="email" label={translate('.fields.email')} required />
              <InputField name="password" type="password" label={translate('.fields.password')} required />

              <div className="form-group">
                <div className="col-sm-offset-3 col-sm-9">
                  <button className="btn btn-primary" type="submit" disabled={submitting}>
                    <FormattedMessage id="buttons.create" />
                  </button>
                  <Link to={`/companies/${companyId}/users`} className="btn btn-danger">
                    <FormattedMessage id="buttons.cancel" />
                  </Link>
                </div>
              </div>
            </form>
          )} />
  </div>;
}

UserForm.propTypes = {
  // Id of the company to which the created user will belong
  companyId: PropTypes.string.isRequired,

  // Function to handle passing the submitted form values to the server
  onSubmit:  PropTypes.func.isRequired
};

export default UserForm;
