import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';

import useEnv from 'lib/use-env';

import { useResource, PAGE_SIZE, baseURL, deleteCacheFor } from '../api';

export const useUsers = () => {
  const { companyId } = useParams();
  const [searchParams] = useSearchParams();
  const [env] = useEnv();

  return useResource('/users', {
    env,
    company_id: companyId,
    "page[number]": searchParams.get('page') || 1,
    "page[size]": PAGE_SIZE
  });
}

export const createUser = async (companyId, params) => {
  const resp = await axios.post(`${baseURL}/users`, { company_id: companyId, ...params });

  deleteCacheFor('users');

  return resp;
};
