import { useNavigate, useParams } from 'react-router-dom';

import { createUser } from 'lib/api/users';
import { useFinalFormHandler } from 'lib/api';

import UserForm from 'components/user-form';

const CreateUser = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const handleUserCreate = useFinalFormHandler(async (params) => {
    await createUser(companyId, params);
    navigate(`/companies/${companyId}/users`);
  });

  return <UserForm companyId={companyId} onSubmit={handleUserCreate} />;
}

export default CreateUser;
