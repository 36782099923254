import { FormattedMessage } from 'react-intl';

import useEnv from 'lib/use-env';

const EnvLabel = () => {
  const [env] = useEnv();

  return <div className={`label label-environment pull-right label-${env}`}>
    <FormattedMessage id={`env.${env}`} />
  </div>;
}

export default EnvLabel;
