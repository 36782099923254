import { FormattedMessage } from 'react-intl';
import { Link, useSearchParams } from 'react-router-dom';

import { useCompanies } from 'lib/api/companies';
import useI18n from 'lib/use-i18n';

import PaginatedList from 'components/paginated-list';

const Company = ({ record }) => {
  const { translate } = useI18n();

  return <tr>
    <td className="text-break">
      <Link to={`/companies/${record.id}`}>{record.name}</Link>
    </td>
    <td className="text-break">{record.city}</td>
    <td className="text-break">{record.token.note}</td>

    <td className="text-break text-right">
      <ul className="list-inline">
        <li>
          <Link to={`/companies/${record.id}/users`}
                aria-label={translate('users.title')}>
            <span className="glyphicon glyphicon-user" />
          </Link>
        </li>
        <li>
          <Link to={`/companies/${record.id}/frame_contracts`}
                aria-label={translate('frame_contracts.title')}>
            <span className="glyphicon glyphicon-file" />
          </Link>
        </li>
      </ul>
    </td>
  </tr>;
};

const ListCompanies = () => {
  const { translate } = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get('name');

  const searchEntered = (event) => {
    if (event.key === 'Enter') {
      if (event.target.value)
        searchParams.set('name', event.target.value);
      else
        searchParams.delete('name');
      setSearchParams(searchParams);
    }
  };

  return <>
    <div className="panel panel-default">
      <div className="panel-body">
        <div className="form-inline company-filter">
          <div className="form-group">
            <input type="text"
                   role="searchbox"
                   size="55"
                   placeholder={translate('companies.search')}
                   className="form-control"
                   defaultValue={name}
                   onKeyPress={searchEntered} />
          </div>
          <div className="form-group new-company">
            <Link to="/companies/new">
              <span className="glyphicon glyphicon-plus" />
              &nbsp;
              <FormattedMessage id="companies.new" />
            </Link>
          </div>
        </div>
      </div>
    </div>
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th className="col-md-4">
            <FormattedMessage id="companies.fields.name" />
          </th>
          <th className="col-md-3">
            <FormattedMessage id="companies.fields.city" />
          </th>
          <th className="col-md-3">
            <FormattedMessage id="companies.fields.token_id" />
          </th>
          <th className="col-md-2">&nbsp;</th>
        </tr>
      </thead>
      <PaginatedList type="companies" axiosHook={useCompanies()} Row={Company} />
    </table>
  </>
};

export default ListCompanies;
