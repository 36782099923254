import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import useI18n from 'lib/use-i18n';
import { enumToOptions, tokensToOptions } from 'lib/api/enums';

import InputField from './input-field';
import SelectField from './select-field';
import FileField from './file-field';

import { Link } from 'react-router-dom';

const toFormData = (attrs) => {
  const formData = new FormData();

  // Avoid adding undefined/null as a value to FormData objects.
  // They get passed as string values 'undefined' and 'null' respectively.
  ['name', 'city', 'uri', 'career_site_url', 'linkedin_reference'].forEach(attr => {
    formData.append(attr, attrs[attr] || '');
  });
  ['industry', 'company_size', 'token_id'].forEach(attr => {
    formData.append(attr, attrs[attr] ? attrs[attr].value : '');
  });
  ['xing', 'linkedin'].forEach(attr => {
    const value = attrs[`${attr}_profile_url`];
    const name = `profile_urls[${attr}]`;
    formData.append(name, value || '');
  });

  if (attrs.logo && attrs.logo.length > 0)
    formData.append('logo', attrs.logo[0]);

  return formData;
};

const CompanyForm = ({ initialValues, onSubmit, submitTitle, cancelTo, industries, companySizes, tokens }) => {
  const { translate } = useI18n('companies');

  return <div className="container-fluid padded">
    <Form initialValues={initialValues}
          onSubmit={(attrs) => onSubmit(toFormData(attrs))}
          render={({ handleSubmit, submitting, submitErrors }) => (
            <form className="form-horizontal" onSubmit={handleSubmit}>
              <InputField name="name" label={translate('.fields.name')} required />
              <SelectField name="industry" label={translate('.fields.industry')} clearable
                           options={enumToOptions(industries)} />
              <InputField name="city" label={translate('.fields.city')} />
              <SelectField name="company_size" label={translate('.fields.company_size')} clearable
                           options={enumToOptions(companySizes)} />
              <InputField name="uri" label={translate('.fields.uri')} />
              <InputField name="career_site_url" label={translate('.fields.career_site_url')} />
              <InputField name="xing_profile_url" label={translate('.fields.xing_profile_url')} />
              <InputField name="linkedin_profile_url" label={translate('.fields.linkedin_profile_url')} />
              {submitErrors && submitErrors.profile_urls &&
                <div className="form-group has-error">
                  <div className="col-sm-offset-3 col-sm-5">
                    {submitErrors.profile_urls.map((error) => (
                      <span key={error} className="help-block">{error}</span>
                    ))}
                  </div>
                </div>}
              <InputField name="linkedin_reference" label={translate('.fields.linkedin_reference')} />
              <FileField name="logo" label={translate('.fields.logo')} />
              <SelectField name="token_id" label={translate('.fields.token_id')}
                           options={tokensToOptions(tokens)} />

              <div className="form-group">
                <div className="col-sm-offset-3 col-sm-9">
                  <button className="btn btn-primary" type="submit" disabled={submitting}>
                    {submitTitle}
                  </button>
                  <Link to={cancelTo} className="btn btn-danger">
                    <FormattedMessage id="buttons.cancel" />
                  </Link>
                </div>
              </div>
            </form>
          )} />
  </div>;
}

CompanyForm.propTypes = {
  // Initial values to be used for the form.
  initialValues: PropTypes.object,

  // A translated string to be used as the submit button text
  submitTitle:   PropTypes.string.isRequired,

  // Function to handle passing the submitted form values to the server
  onSubmit:      PropTypes.func.isRequired,

  // Route to navigate to when the cancel button is clicked
  cancelTo:      PropTypes.string.isRequired,

  // List of industries, returned by useIndustries
  industries:    PropTypes.array.isRequired,

  // List of company sizes, returned by useCompanySizes
  companySizes:  PropTypes.array.isRequired,

  // List of access tokens, returned by useTokens
  tokens:        PropTypes.array.isRequired
};

export default CompanyForm;
