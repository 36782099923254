import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Label } from './forms';

const TextareaField = ({ name, label, required }) => (
  <Field name={name}>
    {({ input, meta }) => (
      <div className={`form-group ${meta.submitError ? 'has-error' : ''}`}>
        <Label name={name} title={`${label}${required ? ' *' : ''}`} />
        <div className="col-sm-5">
          <textarea className="form-control" id={name} {...input} />
        </div>
        <div className="col-sm-4">
          {meta.submitError &&
            <span className="help-block">{meta.submitError}</span>
          }
        </div>
      </div>
    )}
  </Field>
);

TextareaField.propTypes = {
  // The name of the form field handled by this input control
  name:     PropTypes.string.isRequired,

  // A translation of the label for this field
  label:    PropTypes.string.isRequired,

  // Flag causing the field to be marked as required
  required: PropTypes.bool
};

export default TextareaField;
