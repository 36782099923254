import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useI18n from 'lib/use-i18n';
import { forgotPassword } from 'lib/api/sessions';
import { useFinalFormHandler } from 'lib/api';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { translate } = useI18n();

  const handleResetPassword = useFinalFormHandler(async (params) => {
    await forgotPassword(params);

    toast.info(translate('sessions.forgot.success', { email: params.email }));
    navigate('/sessions/new');
  });

  return <>
    <h2><FormattedMessage id="sessions.forgot.header" /></h2>
    <p><FormattedMessage id="sessions.forgot.description" /></p>

    <Form onSubmit={handleResetPassword}
          render={({ handleSubmit, submitting }) => (
            <form className="form-vertical" onSubmit={handleSubmit}>
              <Field name="email">
                {({ input, meta }) => (
                  <div className="form-group">
                    <input className="form-control" {...input} autoFocus />
                    {meta.submitError &&
                      <span className="help-block">{meta.submitError}</span>
                    }
                  </div>
                )}
              </Field>

              <div className="clearfix">
                <button type="submit" disabled={submitting}
                        className="btn btn-primary btn-large button">
                  <FormattedMessage id="sessions.forgot.submit" />
                </button>
                <Link to="/sessions/new" className="login-link pull-right">
                  <FormattedMessage id="sessions.forgot.to_login" />
                </Link>
              </div>
            </form>
          )} />
  </>;
}

export default ForgotPassword;
