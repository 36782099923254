import axios from 'axios';

import { useResource, baseURL } from '../api';

export function useAdminUsers() {
  return useResource('/partner_users', {}, false);
}

export function createAdminUser(params) {
  return axios.post(`${baseURL}/partner_users`, params);
}

export function deleteAdminUser(adminUserId) {
  return axios.delete(`${baseURL}/partner_users/${adminUserId}`);
}
