import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const isActive = (to, pathname, end, except) => {
  if (except && pathname.endsWith(except)) return false;

  if (end) return pathname === to;

  return pathname.startsWith(to);
}

const NavTab = ({ to, title, end, icon, className, except }) => {
  const { pathname } = useLocation();

  const active = isActive(to, pathname, end, except);

  return <li className={`${className || ''}${active ? ' active' : ''}`}>
    <Link to={to}>
      <span className={`glyphicon glyphicon-${icon}`}></span>
      &nbsp;
      <FormattedMessage id={title} />
    </Link>
  </li>;
}

NavTab.propTypes = {
  // Route to navigate to. See react-router Link component.
  to:        PropTypes.string.isRequired,

  // I18n key for the text label to be displayed
  title:     PropTypes.string.isRequired,

  // When true, link will only be shown as active when the
  // current route exactly matches the link route.
  // When false, link will be displayed as active for any
  // subroute.
  end:       PropTypes.bool,

  // Name of the glyphicon to be displayed in the link
  icon:      PropTypes.string.isRequired,

  // Additional CSS class to be added to the <li> element.
  className: PropTypes.string,

  // When present, does not show the link as active if the
  // current route ends with this string.
  except:    PropTypes.string
};

export default NavTab;
