import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';

import useI18n from 'lib/use-i18n';
import { signInOtp } from 'lib/api/sessions';

const SignInOtp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { translate } = useI18n();

  useEffect(() => {
    if (!(state && state.challenge)) {
      toast.info(translate('errors.signed_out'));
      navigate('/sessions/new');
    }
  }, [translate, navigate, state]);

  const handleOtpLogin = async (params) => {
    if (!params.token)
      return { token: translate('errors.blank_code') };

    const { challenge } = state;
    try {
      await signInOtp({ ...params, challenge });
      navigate('/');
    } catch (error) {
      const { response } = error;
      if (response) {
        // Devise redirects us to a URL that does not exist if the token is incorrect
        if (response.status === 404 && response.request.responseURL.endsWith('/auth/sign_in'))
          return { token: translate('errors.otp_token') };

        if (response.data)
          return response.data.errors;
      }
    }
  };

  return <>
    <h2><FormattedMessage id="sessions.otp.title" /></h2>
    <p><FormattedMessage id="sessions.otp.description" /></p>

    <Form onSubmit={handleOtpLogin}
          render={({ handleSubmit, submitting }) => (
            <form className="form-vertical" onSubmit={handleSubmit}>
              <Field name="token">
                {({ input, meta }) => (
                  <div className="form-group">
                    <label htmlFor="token" className="control-label">
                      <FormattedMessage id="sessions.otp.token" />
                    </label>
                    <input className="form-control" {...input} autoFocus maxLength={6} />
                    {meta.submitError &&
                      <span className="help-block">{meta.submitError}</span>
                    }
                  </div>
                )}
              </Field>

              <div className="clearfix">
                <Link to="/sessions/new" className="btn btn-secondary btn-large button">
                  <FormattedMessage id="buttons.cancel" />
                </Link>
                <button type="submit" disabled={submitting}
                        className="btn btn-primary btn-large pull-right button">
                  <FormattedMessage id="sessions.otp.submit" />
                </button>
              </div>
            </form>
          )} />
  </>;
};

export default SignInOtp;
