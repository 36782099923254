import { Outlet } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import EnvLabel from 'components/env-label';

const Companies = () => (
  <>
    <div className="page-header">
      <EnvLabel />
      <h1><FormattedMessage id="companies.title" /></h1>
    </div>
    <Outlet />
  </>
);

export default Companies;
