import { Outlet, Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import logo from 'images/logo.svg';

import Loader from 'components/loader';
import HeaderLink from 'components/header-link';
import AxiosError from 'components/axios-error';

import useI18n from 'lib/use-i18n';
import { signOut, useSession } from 'lib/api/sessions';
import useEnv from 'lib/use-env';
import useLocale from 'lib/use-locale';

// TODO: Use a react component for the dropdown menu, then get rid of these imports
import jQuery from 'jquery';
window.jQuery = window.$ = jQuery;
require('bootstrap');

const App = () => {
  const [{ data, loading, error }] = useSession();
  const [env, setEnv] = useEnv();
  const setLocale = useLocale()[1];

  const navigate = useNavigate();

  const { translate } = useI18n();

  if (loading)
    return <Loader />;

  if (error)
    return <div className="text-center danger">
      <AxiosError error={error} type="app" />
    </div>;

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      toast.error(translate('errors.sign_out'));
      return;
    }
    navigate('/sessions/new');
    setTimeout(() => window.location.reload());
  };

  const { session } = data;

  return (
    <>
      <header>
        <nav className="navbar navbar-default navbar-fixed-top" role="navigation">
          <div className="container">
            <div className="navbar-header">
              <button className="navbar-toggle" data-target="#navbar" data-toggle="collapse" type="button">
                <span className="sr-only">
                  <FormattedMessage id="toggle_nav" />
                </span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div className="navbar-brand">
                <img alt={translate('gohiring.name')} className="img-responsive" width="100" src={logo} />
              </div>
            </div>
            <div className="collapse navbar-collapse" id="navbar">
              <ul className="nav navbar-nav navbar-right">
                <HeaderLink to="/companies" title="companies.title" />
                <HeaderLink to="/orders" title="orders.title" />
                <li>
                  <a target="zendesk" href="https://support.gohiring.com">
                    <FormattedMessage id="zendesk" />
                  </a>
                </li>
                <li className="dropdown">
                  <button className="btn btn-link dropdown-toggle" data-toggle="dropdown">
                    {session.email}
                    <b className="caret"></b>
                  </button>
                  <ul className="dropdown-menu">
                    <li className={env === 'sandbox' ? 'active' : ''}>
                      <a href="#" onClick={() => setEnv('sandbox')}>
                        <FormattedMessage id="env.sandbox" />
                      </a>
                    </li>
                    <li className={env === 'live' ? 'active' : ''}>
                      <a href="#" onClick={() => setEnv('live')}>
                        <FormattedMessage id="env.live" />
                      </a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <Link to="/settings">
                        <FormattedMessage id="settings.title" />
                      </Link>
                    </li>
                    {session.admin &&
                      <li>
                        <Link to="/admin">
                          <FormattedMessage id="admin.title" />
                        </Link>
                      </li>
                    }
                    <li>
                      <a href="#" onClick={handleSignOut}>
                        <FormattedMessage id="buttons.logout" />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="container">
        <Outlet />
      </main>
      <footer>
        <div className="container">
          <div className="pull-left">
            <button className="btn btn-link" onClick={() => setLocale('en')}>
              EN
            </button>
              |
            <button className="btn btn-link" onClick={() => setLocale('de')}>
              DE
            </button>
              |&nbsp;
            <span id="system-status">
              <span className="glyphicon glyphicon-question-sign"></span>
              &nbsp;
              <a href="https://status.gohiring.com" id="status_page_url">
                <span><FormattedMessage id="status" /></span>
              </a>
            </span>
          </div>
          <div className="pull-right">
            <FormattedMessage id="gohiring.copy" />
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
