/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

/**
 * The button bar looks like:
 *  1 ... x y z ... n
 * (y is the current page)
 *
 * This function calculates x.
 */
const lowerBound = (page, pages) => {
  // If we're close enough to the first page (or there aren't many pages)
  // The bar should look like:
  // 1 2 3 4 5 ... n
  if (pages <= 10 || page < 5) {
    return 1;
  } else {
    // If we're close enough to the end, show buttons for all of the
    // 5 last pages.
    return Math.min(page - 1, pages - 4);
  }
}

/**
 * The button bar looks like:
 *  1 ... x y z ... n
 * (y is the current page)
 *
 * This function calculates z.
 */
const upperBound = (page, pages) => {
  // If we're close enough to the last page (or there aren't many pages)
  // The bar should look like:
  // 1 ... x y z n
  if (pages <= 10 || page > (pages - 4)) {
    return pages;
  } else {
    // If we're close enough to the start, show buttons for at least
    // the first 5 pages.
    return Math.max(page + 1, 5);
  }
}

// A numbered pagination button.
const PageButton = (props) => (
  <li className={'page' + (props.selected ? ' active' : '')}>
    <a href="#" onClick={props.onClick}>
      {props.page}
    </a>
  </li>
);
PageButton.propTypes = {
  page:     PropTypes.number.isRequired,
  selected: PropTypes.bool,
  onClick:  PropTypes.func
};

// An unclickable "..." button.
const Ellipsis = () => (
  <li className="gap">
    <span>...</span>
  </li>
);

const Pager = (props) => {
  var pages = Math.ceil(props.count / props.size);
  var lower = lowerBound(props.page, pages);
  var upper = upperBound(props.page, pages);

  // Returns a handler function that generates a page change event,
  // unless a handler is not required.
  function goTo(page) {
    if (page < 1 || page > pages || !props.onPage || page === props.page)
      return;

    return (event) => {
      event.preventDefault();
      props.onPage(page);
    };
  }

  return <nav>
    {/* "Page x of y" info message */}
    <div>
      <FormattedMessage id="x_of_y" values={{ x: props.page, y: pages }} />
    </div>

    {/* Pagination buttons */}
    <ul className="pagination">
      {props.page !== 1 && <>
        <li className="first">
          <a href="#" onClick={goTo(1)}>
            « <FormattedMessage id="pager.first" />
          </a>
        </li>

        <li className="prev">
          <a href="#" onClick={goTo(props.page - 1)}>
            ‹ <FormattedMessage id="pager.prev" />
          </a>
        </li>

        <li className="page">
          <a href="#" onClick={goTo(1)}>1</a>
        </li>

        <Ellipsis />
      </>}


      {/* the main strip of numbered page buttons */}
      {Array.from(Array(upper - lower + 1).keys()).map((i) => {
        var page = lower + i;
        return <PageButton key={i}
                           page={page}
                           onClick={goTo(page)}
                           selected={page === props.page} />
      })}

      {upper !== pages && <>
        <Ellipsis />

        <li className="page">
          <a href="#" onClick={goTo(pages)}>{pages}</a>
        </li>

        <li className="next">
          <a href="#" onClick={goTo(props.page + 1)}>
            <FormattedMessage id="pager.next" /> ›
          </a>
        </li>

        <li className="last">
          <a href="#" onClick={goTo(pages)}>
            <FormattedMessage id="pager.last" /> »
          </a>
        </li>
      </>}
    </ul>
  </nav>;
};
Pager.propTypes = {
  page:       PropTypes.number.isRequired,
  size:       PropTypes.number.isRequired,
  count:      PropTypes.number.isRequired,
  onPage:     PropTypes.func.isRequired
};

export default Pager;
