import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const NotFound = ({ type }) => (
  <h3>
    <span className="glyphicon glyphicon-question-sign" />
    &nbsp;
    <FormattedMessage id={`${type}.not_found`} />
  </h3>
);

const AxiosError = ({ error, type }) => {
  const navigate = useNavigate();
  const { response } = error;

  useEffect(() => {
    if (response && (response.status === 401 || response.status === 403))
      navigate('/sessions/new');
  }, [response, navigate]);

  if (response && response.status === 404)
    return <NotFound type={type} />;

  return <h3>
    <span className="glyphicon glyphicon-fire" />
    &nbsp;
    <FormattedMessage id='errors.server' />
  </h3>;
};

AxiosError.propTypes = {
  // The error object returned by useAxios
  error: PropTypes.object.isRequired,

  // The type of data requested from the server. Used for translations
  type:  PropTypes.string
};

export default AxiosError;
