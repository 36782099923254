import AsyncSelect from 'react-select/async';

import useI18n from 'lib/use-i18n';
import useEnv from 'lib/use-env';
import { searchCompanies } from 'lib/api/companies';

const CompanySelect = ({ value, onSelect }) => {
  const { translate } = useI18n();
  const [env] = useEnv();

  const loadOptions = async (input) => (
    (await searchCompanies(env, input)).data.map(({ id, name }) => ({ value: id, label: name }))
  );

  return <AsyncSelect cacheOptions defaultOptions isClearable={true}
                      key={env}
                      placeholder={translate('orders.search.company')}
                      value={value}
                      onChange={item => onSelect(item)}
                      loadOptions={loadOptions} />
};

export default CompanySelect;
