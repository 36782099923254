import { useResource } from '../api';
import useLocale from 'lib/use-locale';
import useEnv from 'lib/use-env';

export const useIndustries = () => useResource('/industries', { locale: useLocale()[0] });

export const useCompanySizes = () => useResource('/company_sizes', { locale: useLocale()[0] });

export const useTokens = () => useResource('/tokens', { env: useEnv()[0] });

export const enumToOptions = (values) => (
  values.map(value => ({ label: value.value, value: value.code }))
);

export const enumToOption = (values, code) => (
  code && { value: code, label: values.find(value => value.code === code).value }
);

export const tokenToOption = (token) => (
  { value: token.id, label: `(${token.environment}) ${token.note}` }
);

export const tokensToOptions = (tokens) => (
  tokens.map((token) => tokenToOption(token)).sort((a, b) => a.label.localeCompare(b.label))
);
