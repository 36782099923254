import { IntlProvider } from 'react-intl';
import deMessages from './translations/de.json';
import enMessages from './translations/en.json';

import useLocale from './lib/use-locale';

const Intl = ({ children }) => {
  const [locale] = useLocale();

  return <IntlProvider locale={locale}
                       messages={locale === 'de' ? deMessages : enMessages}>
    {children}
  </IntlProvider>;
}

export default Intl;
