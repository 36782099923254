import { Outlet, useParams } from 'react-router-dom';

import { useCompany } from 'lib/api/companies';

import NavTab from 'components/nav-tab';
import Loader from 'components/loader';
import AxiosError from 'components/axios-error';

const Thumbnail = ({ url }) => (
  url ?
    <img className='media-object img-thumbnail' src={url} width="100" alt="logo" /> :
    <media-object role="img" aria-label="logo placeholder" className="img-thumbnail"
      style={{
        width: '100px',
        height: '100px',
        fontSize: '50px',
        lineHeight: '100px',
        textAlign: 'center'
      }}>
      <span className="glyphicon glyphicon-picture"></span>
    </media-object>
);

const Company = () => {
  const { companyId } = useParams();
  const [{ data, loading, error }] = useCompany();

  if (loading) return <Loader />;

  if (error) return <AxiosError error={error} type="companies" />;

  const { company } = data;

  return <>
    <header>
      <div className="panel-heading">
        <div className="media">
          <div className="pull-left">
            <Thumbnail url={company.logo_url} />
          </div>
          <div className="media-body">
            <h2 className="media-heading">{company.name}</h2>
            <p className="lead">
              {company.city}
              {company.uri && <>
                &nbsp;·&nbsp;
                <a target="_blank" rel="noreferrer" href={company.uri}>
                  {company.uri}
                </a>
              </>}
            </p>
          </div>
        </div>
      </div>

      <div className="panel-body">
        <ul className="nav nav-tabs" role="tablist">
          <NavTab to={`/companies/${companyId}`} title="companies.title" icon="home" end />
          <NavTab to={`/companies/${companyId}/users`}
                  title="users.title"
                  icon="user"
                  except="/new" />
          <NavTab to={`/companies/${companyId}/frame_contracts`}
                  title="frame_contracts.title"
                  icon="file"
                  except="/new" />

          <NavTab to={`/companies/${companyId}/frame_contracts/new`}
                  className="pull-right"
                  title="frame_contracts.new"
                  icon="plus" />
          <NavTab to={`/companies/${companyId}/users/new`}
                  className="pull-right"
                  title="users.new"
                  icon="plus" />
        </ul>
      </div>
    </header>
    <Outlet />
  </>;
};

export default Company;
