import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import { FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';

import useI18n from 'lib/use-i18n';
import useEnv from 'lib/use-env';
import { baseURL, useOrders, orderQueryString } from 'lib/api';
import { selectItemToString, stringToSelectItem } from 'lib/react-select-items';

import PaginatedList from 'components/paginated-list';
import EnvLabel from 'components/env-label';
import CompanySelect from 'components/company-select';

const Order = ({ record }) => {
  const { translate } = useI18n();

  return <tr>
    <td>
      <FormattedDate value={Date.parse(record.confirmed_at)} />
      <br />
      <FormattedTime value={Date.parse(record.confirmed_at)} />
    </td>
    <td className="text-break">{record.id}</td>
    <td className="text-break">{record.company_name}</td>
    <td className="text-break">{record.position}</td>
    <td>
      <LinesEllipsis text={record.product_names}
                     maxLine={2}
                     ellipsis='...' />
    </td>
    <td className="text-right">
      <strong>
        <FormattedNumber value={record.subtotal}
                         {...{ style: 'currency' } /* makes the linter happy */}
                         currency="EUR" />
      </strong>
    </td>
    <td className="text-center">
      {(record.invoice_state && record.invoice_state !== 'submitted') ?
        <a href={`${baseURL}/orders/${record.id}/invoice`}
           aria-label={translate('orders.invoice')}
           target="_blank" rel="noreferrer" download>
          <i className="glyphicon glyphicon-download" />
        </a> :
        <FormattedMessage id="orders.invoice_unavailable" />
      }
    </td>
  </tr>;
};

const OrderFilters = () => {
  const query = useRef(null);
  const periodFrom = useRef(null);
  const periodTo = useRef(null);

  const { translate } = useI18n();

  const [searchParams, setSearchParams] = useSearchParams();
  const [company, setCompany] = useState(() => (
    stringToSelectItem(searchParams.get('company'))
  ));

  const onSetFilters = () => {
    const params = {};
    if (company)
      params.company = selectItemToString(company);
    if (query.current.value !== '')
      params.query = query.current.value;
    if (periodFrom.current.value !== '')
      params.from = periodFrom.current.value;
    if (periodTo.current.value !== '')
      params.to = periodTo.current.value;
    setSearchParams(params);
  };

  const resetFilters = () => {
    setCompany(null);
    query.current.value = '';
    periodFrom.current.value = '';
    periodTo.current.value = '';
    setSearchParams({});
  };

  return <div className="form-inline order-filter">
    <div className="form-group">
      <CompanySelect value={company} onSelect={setCompany} />
    </div>
    <div className="form-group">
      <input ref={query}
             role="searchbox"
             defaultValue={searchParams.get('query')}
             className="form-control"
             placeholder={translate('orders.search.placeholder')}
             size="55"
             type="search" />
    </div>

    <div className="form-group">
      <h4>
        <FormattedMessage id="orders.search.period" />
      </h4>
      <label htmlFor="periodFrom">
        <FormattedMessage id="from" />
      </label>
      <input ref={periodFrom} id="periodFrom"
             className="form-control" type="date"
             defaultValue={searchParams.get('from')} />

      <label htmlFor="periodTo">
        <FormattedMessage id="to" />
      </label>
      <input ref={periodTo} id="periodTo"
             className="form-control" type="date"
             defaultValue={searchParams.get('to')} />
    </div>

    <div className="form-group">
      <button className="btn btn-primary" type="submit" onClick={onSetFilters}>
        <FormattedMessage id="buttons.filter" />
      </button>
      &nbsp;
      <a onClick={resetFilters}>
        <FormattedMessage id="buttons.reset" />
      </a>
    </div>
  </div>
};

const ListOrders = () => {
  const [env] = useEnv();
  const [searchParams] = useSearchParams();
  const ordersHook = useOrders();
  const [{ data }] = ordersHook;

  return <>
    <div className="page-header">
      <EnvLabel />
      <h1>
        <FormattedMessage id="orders.title" />
      </h1>
    </div>

    <OrderFilters />

    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th className="col-md-2">
            <FormattedMessage id="orders.fields.confirmed_at" />
          </th>
          <th className="col-md-2">
            <FormattedMessage id="orders.fields.id" />
          </th>
          <th className="col-md-2">
            <FormattedMessage id="orders.fields.company_name" />
          </th>
          <th className="col-md-3">
            <FormattedMessage id="orders.fields.position" />
          </th>
          <th className="col-md-3">
            <FormattedMessage id="orders.fields.product_names" />
          </th>
          <th className="col-md-2 text-right">
            <FormattedMessage id="orders.fields.subtotal" />
          </th>
          <th className="col-md-1">
            <FormattedMessage id="orders.invoice" />
          </th>
        </tr>
      </thead>

      <PaginatedList type="orders" axiosHook={ordersHook} Row={Order} />
    </table>

    {data && data.orders && data.orders.length > 0 &&
      <a className="btn btn-default pull-right"
         href={`${baseURL}/orders/csv?${orderQueryString(env, searchParams)}`}>
        <FormattedMessage id="orders.csv" />
      </a>
    }
  </>;
};

export default ListOrders;
