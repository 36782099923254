import PropTypes from 'prop-types';
import Select from 'react-select';
import { Field } from 'react-final-form';

import { Label } from './forms';

const SelectField = ({ name, label, required, options, clearable }) => (
  <Field name={name} className="form-group">
    {({ input, meta }) => (
      <div className={`form-group ${meta.submitError ? 'has-error' : ''}`}>
        <Label name={name} title={`${label}${required ? ' *' : ''}`} />

        <div className="col-sm-5">
          <Select inputId={name} {...input} options={options} isClearable={clearable} />
        </div>

        <div className="col-sm-4">
          {meta.submitError &&
            <span className="help-block">{meta.submitError}</span>
          }
        </div>
      </div>
    )}
  </Field>
);

SelectField.propTypes = {
  // The name of the form field handled by this input control
  name:      PropTypes.string.isRequired,

  // A translation of the label for this field
  label:     PropTypes.string.isRequired,

  // Flag causing the field to be marked as required
  required:  PropTypes.bool,

  // A list of options available to be selected
  options:   PropTypes.arrayOf(PropTypes.shape({
    // The translated label to be shown for the item
    label: PropTypes.string.isRequired,

    // The value to set the field to when this item is selected
    value: PropTypes.string.isRequired
  })),

  // Flag causing the select field to display a button for
  // clearing the currently selected value
  clearable: PropTypes.bool
};

export default SelectField;
