import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import CompanyForm from 'components/company-form';
import Loader from 'components/loader';

import { useAll, useFinalFormHandler } from 'lib/api';
import { useCompanySizes, useIndustries, useTokens } from 'lib/api/enums';
import { createCompany } from 'lib/api/companies';
import useI18n from 'lib/use-i18n';

const CreateCompany = () => {
  const { company_sizes: companySizes, industries, tokens, loading, error } = useAll({
    company_sizes: useCompanySizes(), industries: useIndustries(), tokens: useTokens()
  });
  const navigate = useNavigate();

  const { translate } = useI18n();

  const handleCompanyCreate = useFinalFormHandler(async (formData) => {
    const response = await createCompany(formData);
    navigate(`/companies/${response.data.company.id}`);
  });

  if (loading)
    return <Loader />;

  if (error)
    return <div className="text-center">
      <h4>
        <span className="glyphicon glyphicon-fire"></span>
        &nbsp;
        <FormattedMessage id="errors.server" />
      </h4>
    </div>;

  return <CompanyForm onSubmit={handleCompanyCreate}
                      submitTitle={translate('companies.create')}
                      cancelTo="/companies"
                      industries={industries}
                      companySizes={companySizes}
                      tokens={tokens} />;
}

export default CreateCompany;
