import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { Label } from './forms';

import filesize from 'filesize';

const Inner = ({ label, input, meta }) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop: (acceptedFiles) => {
      input.onChange(acceptedFiles)
    }
  });

  return <div className={`form-group ${meta.submitError ? 'has-error' : ''}`}>
    <Label title={label} />

    <div className="col-sm-5" {...getRootProps()}>
      <input {...getInputProps()} />
      <button type="button" onClick={open}>
        <FormattedMessage id="buttons.choose_image" />
      </button>
      {acceptedFiles.length ? <>
        {acceptedFiles[0].path} &mdash; {filesize(acceptedFiles[0].size)}
      </> : ''}
    </div>

    <div className="col-sm-4">
      {meta.submitError &&
        <span className="help-block">{meta.submitError}</span>
      }
    </div>
  </div>;
}

const FileField = ({ name, label }) => (
  <Field name={name} className="form-group">
    {({ input, meta }) => <Inner input={input} meta={meta} label={label} />}
  </Field>
);

FileField.propTypes = {
  // The name of the form field
  name:  PropTypes.string.isRequired,

  // The translated label to be shown for the field
  label: PropTypes.string.isRequired
};

export default FileField;
