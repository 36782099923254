import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { useChannels, useAll, useFinalFormHandler } from 'lib/api';
import { useFrameContract, updateFrameContract } from 'lib/api/frame-contracts';

import FrameContractForm from 'components/frame-contract-form';
import Loader from 'components/loader';

const EditFrameContract = () => {
  const frameContractHook = useFrameContract();
  const { frame_contract: frameContract, channels, loading, error } = useAll({
    frame_contract: frameContractHook, channels: useChannels()
  });
  const navigate = useNavigate();

  const handleFrameContractUpdate = useFinalFormHandler(async (params) => {
    await updateFrameContract(frameContract.id, params);
    navigate(`/companies/${frameContract.company_id}/frame_contracts/${frameContract.id}`);
  });

  if (loading)
    return <Loader />;

  if (error)
    return <div className="text-center">
      <h4>
        <span className="glyphicon glyphicon-fire"></span>
        &nbsp;
        <FormattedMessage id="errors.server" />
      </h4>
    </div>;

  const frameContractPath = `/companies/${frameContract.company_id}/frame_contracts/${frameContract.id}`;

  return <FrameContractForm initialValues={{
                              ...frameContract,
                              channel_id: {
                                value: frameContract.channel.id,
                                label: frameContract.channel.name
                              },
                              pricing_type: frameContract.pricing_type
                            }}
                            onSubmit={handleFrameContractUpdate}
                            channels={channels}
                            cancelTo={frameContractPath} />
}

export default EditFrameContract;
