import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';

import useEnv from 'lib/use-env';

import { useResource, PAGE_SIZE, baseURL, updateCachedRecord, deleteCacheFor } from '../api';

export const useFrameContracts = () => {
  const { companyId } = useParams();
  const [searchParams] = useSearchParams();
  const [env] = useEnv();

  return useResource('/frame_contracts', {
    env,
    company_id: companyId,
    state: searchParams.get('state'),
    "page[number]": searchParams.get('page') || 1,
    "page[size]": PAGE_SIZE
  });
}

export const useFrameContract = () => {
  const { frameContractId } = useParams();

  return useResource(`/frame_contracts/${frameContractId}`);
}

export const createFrameContract = async (companyId, params) => {
  const resp = await axios.post(`${baseURL}/frame_contracts`, { company_id: companyId, ...params });

  // cache for FCs must be cleared, if the list is paginated, we don't know
  // where a new record should appear
  deleteCacheFor('frame_contracts');

  return resp;
};

export const updateFrameContract = async (frameContractId, params) => {
  const resp = await axios.put(`${baseURL}/frame_contracts/${frameContractId}`, params);

  await updateCachedRecord('frame_contracts', 'frame_contract', resp);

  return resp;
};

export const deleteFrameContract = async (frameContractId) => {
  const resp = await axios.delete(`${baseURL}/frame_contracts/${frameContractId}`);

  deleteCacheFor('frame_contracts');

  return resp;
};
