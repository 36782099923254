import { useCallback, useEffect, useState } from 'react';

import { useEventListener } from './use-event-listener';

const useStorage = (storage, key, initialValue) => {
  const readValue = useCallback(() => {
    try {
      const item = window[storage].getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading ${storage} key "${key}":`, error);
      return initialValue;
    }
  }, [storage, initialValue, key]);

  const [storedValue, setStoredValue] = useState(readValue);

  const setValue = (value) => {
    try {
      // Match useState interface
      const newValue = value instanceof Function ? value(storedValue) : value;

      window[storage].setItem(key, JSON.stringify(newValue));

      setStoredValue(newValue);

      window.dispatchEvent(new Event(`session-storage.${key}`));
    } catch (error) {
      console.warn(`Error setting sessionStorage key "${key}":`, error);
    }
  }

  useEffect(() => { setStoredValue(readValue()) }, [readValue]);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValue());
  }, [readValue]);

  useEventListener(`session-storage.${key}`, handleStorageChange);

  return [storedValue, setValue];
}

export const useSessionStorage = (key, initialValue) => (
  useStorage('sessionStorage', key, initialValue)
);

export const useLocalStorage = (key, initialValue) => (
  useStorage('localStorage', key, initialValue)
);
