import { FormattedMessage } from 'react-intl';
import useClipboard from 'react-use-clipboard';

import { useUsers } from 'lib/api/users';
import useI18n from 'lib/use-i18n';

import PaginatedList from 'components/paginated-list';

const ClipboardButton = ({ value }) => {
  const [isCopied, setCopied] = useClipboard(value, { successDuration: 1000 });
  const { translate } = useI18n();

  if (isCopied)
    return <button aria-label={translate('buttons.clipboard')}
                   className="btn btn-light" disabled>
      <span className="glyphicon glyphicon-ok-circle" />
    </button>;

  return <button aria-label={translate('buttons.clipboard')}
                 className="btn btn-info"
                 onClick={setCopied}>
    <span className="glyphicon glyphicon-copy" />
  </button>;
};

const User = ({ record }) => (
  <tr>
    <td className="text-break">{record.name}</td>
    <td className="text-break">
      <a href={`mailto:${record.email}`}>{record.email}</a>
    </td>
    <td className="text-break">
      {record.authentication_token}
      &nbsp;
      <ClipboardButton value={record.authentication_token} />
    </td>
  </tr>
);

const ListUsers = () => (
  <table className="table table-striped table-hover">
    <thead>
      <tr>
        <th className="col-md-6">
          <FormattedMessage id="users.fields.name" />
        </th>
        <th className="col-md-3">
          <FormattedMessage id="users.fields.email" />
        </th>
        <th className="col-md-3">
          <FormattedMessage id="users.fields.access_token" />
        </th>
      </tr>
    </thead>
    <PaginatedList type="users" axiosHook={useUsers()} Row={User} />
  </table>
);

export default ListUsers;
