import { useIntl } from 'react-intl';

const useI18n = (namespace) => {
  const { formatMessage } = useIntl();

  const translate = (id, opts) => {
    if (id.startsWith('.')) id = `${namespace}${id}`;

    return formatMessage({ id }, opts);
  }

  return { translate };
}

export default useI18n;
