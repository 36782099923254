import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFinalFormHandler } from 'lib/api';
import useI18n from 'lib/use-i18n';

const SetPasswordForm = ({ i18nNamespace, apiCall, tokenName }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { translate } = useI18n(i18nNamespace);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      toast.warning(translate('.no_token'));
      navigate('/sessions/new');
    }
  });

  const handleLogin = useFinalFormHandler(async (params) => {
    await apiCall({ token, ...params });

    navigate('/');
  });

  return <>
    <h2>{translate('.title')}</h2>
    <h3>{translate('.header')}</h3>

    <Form onSubmit={handleLogin}
          render={({ handleSubmit, submitting, submitErrors }) => (
            <form className="form-vertical" onSubmit={handleSubmit}>
              {submitErrors && submitErrors[tokenName] && submitErrors[tokenName].map((error) => (
                <span key={error} className="help-block">{error}</span>
              ))}
              <Field name="password" type="password">
                {({ input, meta }) => (
                  <div className="form-group">
                    {meta.submitError &&
                      <span className="help-block">{meta.submitError}</span>
                    }
                    <label htmlFor="password" className="control-label">
                      {translate('sessions.password')}
                    </label>
                    <input id="password" className="form-control" {...input} autoFocus />
                  </div>
                )}
              </Field>

              <Field name="password_confirmation" type="password">
                {({ input, meta }) => (
                  <div className="form-group">
                    <label htmlFor="password_confirmation" className="control-label">
                      {translate('sessions.password_confirmation')}
                    </label>
                    <input id="password_confirmation" className="form-control" {...input} />
                    {meta.submitError &&
                      <span className="help-block">{meta.submitError}</span>
                    }
                  </div>
                )}
              </Field>

              <div className="clearfix">
                <button type="submit" disabled={submitting}
                        className="btn btn-primary btn-large pull-right button">
                  {translate('.submit')}
                </button>
              </div>
            </form>
          )} />
  </>;
}

SetPasswordForm.propTypes = {
  // I18n namespace to use for translations for header, submit button, etc.
  i18nNamespace: PropTypes.string.isRequired,

  // Async function that accepts a hash of form values and sends a request to the backend.
  apiCall:       PropTypes.func.isRequired,

  // Name of the token as it is returned in errors from the backend.
  tokenName:     PropTypes.string.isRequired
};

export default SetPasswordForm;
