import PropTypes from 'prop-types';

const Label = ({ name, title, required }) => (
  <label htmlFor={name} className="col-sm-3 control-label">{title}{required && ' *'}</label>
);

Label.propTypes = {
  // A translated string to be displayed as the label
  title:    PropTypes.string.isRequired,

  // Flag denoting that the field is required
  required: PropTypes.bool
};

const ShowField = ({ title, value }) => (
  <div className="form-group">
    <Label title={title} />
    <div className="col-sm-9 form-control-static">
      {value || '—'}
    </div>
  </div>
);

ShowField.propTypes = {
  // The name of the field being displayed, should match the input ID
  name: PropTypes.string,

  // A translated string to be displayed as the label
  title: PropTypes.string.isRequired,

  // The current value of the field
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export { Label, ShowField };
