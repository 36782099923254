import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const HeaderLink = ({ to, title }) => {
  const { pathname } = useLocation();

  const active = pathname.startsWith(to);

  return <li className={active ? 'active' : ''}>
    <Link to={to}>
      <FormattedMessage id={title} />
    </Link>
  </li>;
}

HeaderLink.propTypes = {
  // Route to navigate to. See react-router Link component.
  to:    PropTypes.string.isRequired,

  // I18n key for the text label to be displayed
  title: PropTypes.string.isRequired
};

export default HeaderLink;
